<template>
  <div ref="sectionRef" class="pl-page grid-default mb-10 mt-20 lg:my-30">
    <Heading
      v-if="data.title"
      :size="5"
      generic
      class="col-span-9 mb-6"
      :class="data.titlePosition === 'Left' ? 'md:col-span-3 md:mb-0' : 'md:col-span-12 md:mb-20'"
    >
      {{ data.title }}
    </Heading>
    <div
      class="col-span-12 h-[493px] md:h-[40rem] lg:h-[36.375rem]"
      :class="data.titlePosition === 'Left' ? 'md:col-span-9' : 'md:col-span-12'"
    >
      <LazyTestimonials
        v-if="isSectionVisible"
        :data="duplicateTestimonials"
        class="h-full w-full"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useIntersectionObserver } from '@vueuse/core'
import { SectionTestimonialsListRecord } from '~/types/generated'

const props = defineProps({
  data: {
    type: Object as PropType<SectionTestimonialsListRecord>,
    default: () => {}
  }
})

const sectionRef = ref<HTMLElement | null>(null)
const isSectionVisible = ref(false)

// https://stackoverflow.com/questions/75450242/swiper-js-loop-when-slidesperview-is-bigger-than-half-of-the-amount-of-slides
const duplicateTestimonials = computed(() =>
  props.data.testimonials.concat(props.data.testimonials)
)

const { stop } = useIntersectionObserver(sectionRef, ([{ isIntersecting }], _observerElement) => {
  if (isIntersecting) {
    isSectionVisible.value = true
  }
})

onUnmounted(() => {
  stop()
})
</script>
